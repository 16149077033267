import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e8326168"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "_page_wrap"
}
const _hoisted_2 = { class: "_page_content" }
const _hoisted_3 = { class: "_content_title" }
const _hoisted_4 = { class: "asset_images" }
const _hoisted_5 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.store.state.loginState != '')
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("img", {
          id: "go_back",
          src: "images/back.png",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.router.push('/user-home')))
        }),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.currUserAsset.name), 1),
          _createElementVNode("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.currUserAsset.urls, (url) => {
              return (_openBlock(), _createElementBlock("img", {
                key: url,
                src: url,
                alt: "",
                class: "asset_image"
              }, null, 8, _hoisted_5))
            }), 128))
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}