
/* eslint-disable vue/no-unused-components */
import { defineComponent } from "vue";
import {scrollTo} from '@/utils'
import scroll from '@/scroll'
import {useRouter} from 'vue-router'
import {loginState} from '@/loginState'
import {currUserAsset} from './userAsset'
import store from '@/store'

export default defineComponent({
    props: {
        headImage: {
        default: "",
        },
        currTab:{default: ""}
    },
    components:{
    },
    setup(props){
        // disable right-click
        document.addEventListener('contextmenu', event => event.preventDefault());

        return {
            router:useRouter(),
            currUserAsset,
            store
        }
    }
});
